import React from 'react';
import SwitchCheckbox from '../../components/SwitchCheckbox/SwitchCheckbox';
import PreguntasAbiertasDataService from '../../Services/PreguntasAbiertas/PreguntasAbiertas.Service'
import Spinner from '../../components/Spinner';
import PreguntaBrechaService from '../../Services/PreguntaBrecha/PreguntaBrecha.Service';

class ControlPreguntasAbiertas extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            preguntasv2: [],
            loadingv2: true,
            textoAvance: "",
            

            loading: false,
            preguntas: [],
            etapa: {
                idSesion: 0,
                idEtapa: 1,
                activo: false,
                finalizado: false
            },
            etapaActiva: true,
            textoAvance1: "",
            textoAvance2: "",
            textoAvance3: "",
            haComenzado: false,
            infoModal: false,
            countUsersSession: 0,
        }
    }

    // Handlers
    handlerChangePregunta = async (value, id, orden) => {
        this.setState(prevState => ({
            ...prevState,
            textoAvance: ""
        }));
        await this.setActivePregunta(id, value);
    }

    componentDidMount = async () => {
        await this.getEtapa();
        await this.getAllPreguntas();
        await this._getUsersSession();
        await this.setTextoAvance(this.getIdPregunta());
        //Iniciar el timer para que esté actualizando
        this.refreshData();
    }

    //
    // Métodos
    getAllPreguntas = async () => {
        let preguntas = this.state.preguntas;
        //await PreguntasAbiertasDataService.getAll(1)
        await PreguntasAbiertasDataService.getAllV2(1)
            .then(response => {
                //console.log(response.data,"Preguntas");
                preguntas = response.data;
            });
        console.log(preguntas);
        this.setState(prevState => ({
            ...prevState,
            preguntas: preguntas,
            preguntasv2: preguntas,
            loading: false,
            loadingv2 : false,
        }));
        /*this.setState({ preguntasv2: preguntas, loading: false });*/

        console.log(this.state.preguntasv2);
    }

    getIdPregunta() {
        var idPregunta = 0;

        for (var i = 0; i < this.state.preguntasv2.length; i++) {
            if (this.state.preguntasv2[i].avance) {
                idPregunta = this.state.preguntasv2[i].idPregunta;
                break;
            }
        }
        // if(ban)
        // {
        //     idPregunta = this.state.preguntas[id-1].idPregunta;
        // }
        return idPregunta;
    }

    setTextoAvance = async (idPregunta) => {
        console.log(this.state.etapa.activo, "------>", idPregunta);
        if (this.state.etapa.activo) {
            await PreguntasAbiertasDataService.getAvanceV2(1, idPregunta)
                .then(response => {
                    console.log(response.data);
                    this.setState({ textoAvance: response.data + " de " + this.state.countUsersSession + " personas han respondido esta pregunta" })
                });
        }
    }

    setActivePregunta = async (id, ban) => {
        this.setState({ loading: true })
        let preguntas = this.state.preguntas;
        //await PreguntasAbiertasDataService.patch(1, id, ban)
        await PreguntasAbiertasDataService.patchV2(1, id, ban)
            .then(response => {
                preguntas = response.data;
            });
        this.setState(prevState => ({
            ...prevState,
            loading: false,
            preguntas: preguntas,
            preguntasv2:preguntas
        }));
        var idPregunta = this.getIdPregunta();
        await this.setTextoAvance(idPregunta);
        //Volver a activar el contador

    }

    refreshData = async () => {
        try {
            const timeoutId = setTimeout(async () => {
                console.log("refreshData");
                await this.setTextoAvance(this.getIdPregunta());
                await this._getUsersSession()

                //onsole.log("End Refresh Data");
            }, 5000);
        }
        catch (e) {

        }
        finally {
            if (this.state.etapaActiva)
                setTimeout(async () => this.refreshData(), 3000);
        }
    }

    getEtapa = async () => {
        let responseData = null;
        await PreguntasAbiertasDataService.getEtapa(1)
            .then(response => {
                responseData = response.data;
            });

        if (responseData !== null) {
            this.setState(prevState => ({
                ...prevState,
                etapa: responseData
            }));
        }
    }

    activarEtapa = async () => {
        let responseData = null;
        await PreguntasAbiertasDataService.activarEtapa(1)
            .then(response => {
                responseData = response.data;
                console.log(responseData);
            });

        if (responseData !== null) {
            this.setState(prevState => ({
                ...prevState,
                etapa: responseData
            }));
            await this.setActivePregunta(this.state.preguntas[0].idPregunta, true);
        }
    }

    desactivarEtapa = async () => {
        this.setState({ loading: true })
        let responseData = null;
        await PreguntasAbiertasDataService.desactivarEtapa(1)
            .then(response => {
                responseData = response.data;
            });
        if (responseData !== null) {
            await this.moverEtapa();
        }
        this.setState({ loading: false })
    }

    moverEtapa = async () => {
        let responseData = null;
        await PreguntasAbiertasDataService.moverEtapa(2)
            .then(response => {
                responseData = response.data;
            });
        if (responseData != null) {
            console.log("desactivar etapa");
            this.setState({ etapaActiva: false })
            this.props.history.push(`/pregunta-brecha/${this.state.etapa.idSesion}`);
        }
        // this.props.history.push("/pregunta-brecha");
    }

    Comenzar = async () => {
        this.setState({ haComenzado: true, pregunta1Enabled: true })
        await this.activarEtapa();
    }

    Finalizar = async () => {
        await this.desactivarEtapa();
    }

    FinalizarSesion = async () => {
        this.setState({ loading: true })

        await PreguntasAbiertasDataService.desactivarEtapa(1)
            .then(response => {
                console.log(response.data, "Etapa desactivada")
            });

        await PreguntasAbiertasDataService.finalizarSesion()
            .then(response => {
                console.log(response.data, "Sesion finalizada")
            });

        this.setState({ loading: false })
        this.state.etapaActiva = false;

        this.props.history.replace(`/page-end`);

    }


    // ! Get countUsersSession
    _getUsersSession = async () => {
        let res = await PreguntaBrechaService.ObtenerUsuarios(this.state.etapa?.idSesion).then(res => res)
        if (res?.data) {
            this.setState({ countUsersSession: res.data })
        }
    }
    //

    renderQuestions(questions) {
        return (

            <div className="questions-container">

                {questions.map((question, key) => {
                    return (
                        <div className="question" key={key}>
                            <label htmlFor="">Pregunta {question.preguntaProyecto.orden}</label>
                            <div className="description text-justify">
                                <p>{question.preguntaProyecto.nombre !== undefined ? question.preguntaProyecto.nombre || "" : ""}</p>
                                <SwitchCheckbox className="action" checked={question !== undefined ? question.avance || false : false} handlerChange={this.handlerChangePregunta} id={question.idPregunta} orden={question.preguntaProyecto.orden} />
                            </div>
                            <label style={{ display: question.avance ? "block" : "none" }}>{this.state.textoAvance}</label>
                        </div>
                    )
                    }
                )}
            </div>);
    }

    render() {

        let contents = this.state.loadingv2
            ? <p><em>Loading...</em></p>
             : this.renderQuestions(this.state.preguntasv2);

        return (
            this.state.etapa.finalizado !== true ?
                <div className="page-container">
                    <Spinner show={this.state.loading} />
                    <div className="tituloEtapas"><h1>Etapa de preguntas abiertas</h1></div>

                    {contents }
                    {
                        
                        !this.state.etapa.activo ?
                            <>
                                {this.state.preguntas.length != 0 ? <button onClick={() => { this.Comenzar() }} className="btn primary" type="button">Comenzar preguntas abiertas</button> : 
                                    <div className="screen-center">
                                        <div className="admin-message-container">
                                            <h1 className="admin-message-title">
                                                Por favor agregue preguntas antes de iniciar la sesión.
                                            </h1>
                                        </div>
                                    </div>
                            }
                            </>
                            : !this.state.etapa.finalizado ?
                                <div className="botonesFinalizarEtapa">  <button onClick={() => { this.Finalizar() }} className="btn danger" type="button">Finalizar preguntas abiertas</button>  <button onClick={() => { this.FinalizarSesion() }} className="btn danger" type="button">Finalizar Sesión</button> </div> :
                                <button className="btn" type="button" disabled="disabled" >Etapa finalizada</button>
                    }
                    <div className={`modalContainer ${this.state.infoModal ? "active" : ""}`}>
                        <div className="modalMessage">
                            <div className="modalHeader">
                                <p>ABP Virtual</p>
                                <small>Sistema de consulta participativa</small>
                            </div>
                            <div className="modalBody">
                                <p>El sistema de consulta participativa ABP Virtual permite obtener datos muestra de los usuarios que participen haciendo uso de un token cifrado. Las respuestas que el usuario o participante de la sesión registre en esta aplicación, se almacena en una base de datos que posteriormente el equipo de administración procesará.</p>
                                <p>El sistema le brinda un usuario temporal, de manera que no se almacenan otros datos personales de su ordenador. Si tiene problemas con la aplicación, siéntase con la libertad de dirigirse al facilitador del evento  a través del chat de Zoom.</p>
                            </div>
                            <div className="modalFooter">
                                <button type="button" onClick={() => { this.setState({ infoModal: false }) }} className="btn primary">Cerrar mensaje</button>
                            </div>
                        </div>
                    </div>
                    <div className="leftActions">
                        <button onClick={() => { navigator.clipboard.writeText(localStorage.getItem("abplink")) }}><i className="fas fa-link" type="button"></i></button>
                        <button type="button" onClick={() => { this.setState({ infoModal: true }) }}><i className="fas fa-info" type="button"></i></button>
                    </div>
                </div>
                :
                <div className="page-container">
                    <br />
                    La etapa no esta activa
                </div>
        )
    }
}

export default ControlPreguntasAbiertas;