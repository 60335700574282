import React, { Component } from "react";
import {
    HashRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import RouteLayout from "./Admin/Layout/RouteLayout";
import GenerarURL from './Admin/Pages/GenerarURL';
import ControlPreguntasAbiertas from './Admin/Pages/ControlPreguntasAbiertas';
import ControlPreguntaBrecha from './Admin/Pages/ControlPreguntaBrecha';
import ControlPreguntaFinal from './Admin/Pages/ControlPreguntaFinal';
import PageEnd from "./Admin/Pages/PageEnd";

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <div className="App">
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/home" />
                        </Route>
                        <RouteLayout path="/home/:token" component={GenerarURL} />
                        <RouteLayout path="/preguntas-abiertas" component={ControlPreguntasAbiertas} />
                        <RouteLayout path="/pregunta-brecha/:id" component={ControlPreguntaBrecha} />
                        <RouteLayout path="/pregunta-final" component={ControlPreguntaFinal} />
                        <RouteLayout path="/page-end" component={PageEnd} />
                    </Switch>
                </Router>
            </div>
        );
    }
}
