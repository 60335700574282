import React from 'react';
import './SwitchCheckbox.css';

const SwitchCheckbox = ({ enabledColor = "#00b963", disabledColor = "#e44e44", handlerChange = null, checked = false, className = "", id = -1, orden = -1}) => {
    return (

        <div className={`SwitchCheckboxContainer ${className}`}>
            <div className={`Switch ${checked ? "checked" : ""}`}
            style={{backgroundColor: checked ? enabledColor : disabledColor}}
            onClick={(e)=>{handlerChange(!e.currentTarget.classList.contains('checked'), id, orden)}}></div>
        </div>
    );
}
export default SwitchCheckbox;