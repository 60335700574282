import React from 'react';

class PageEnd extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {}
    render() {
        return (
            <div className="screen-center">
                <div className="admin-message-container">
                    <h1 className="admin-message-title">
                        Gracias por tu excelente trabajo como administrador de esta sesión.
                    </h1>
                    <h2 className="admin-message-subtitle">¡Hasta la próxima!</h2>
                </div>
            </div>
        );
    }
}

export default PageEnd;